import __vite__cjsImport0_sweetalert2_dist_sweetalert2 from "/node_modules/.vite/deps/sweetalert2_dist_sweetalert2.js?v=f51620a0"; const SwalPlugin = __vite__cjsImport0_sweetalert2_dist_sweetalert2.__esModule ? __vite__cjsImport0_sweetalert2_dist_sweetalert2.default : __vite__cjsImport0_sweetalert2_dist_sweetalert2;

const Swal = SwalPlugin.mixin({
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-label-danger',
    denyButton: 'btn btn-label-secondary'
  }
});

try {
  window.Swal = Swal;
} catch (e) {}

export { Swal };
